const data_backend = [
  {
    id: 1,
    language: "NodeJS",
    level: "Intermediate",
  },
  {
    id: 2,
    language: "Express",
    level: "Intermediate",
  },
  {
    id: 3,
    language: "MongoDB",
    level: "Experienced",
  },
  {
    id: 4,
    language: "mySQL",
    level: "Intermediate",
  },
  {
    id: 5,
    language: "PHP",
    level: "Basic",
  },
];
export default data_backend;
