const data_frontend = [
  {
    id: 1,
    language: "HTML",
    level: "experienced",
  },
  {
    id: 2,
    language: "CSS",
    level: "Intermediate",
  },
  {
    id: 3,
    language: "JavaScript",
    level: "experienced",
  },
  {
    id: 4,
    level: "experienced",
    language: "Bootstrap",
  },
  {
    id: 5,
    language: "React",
    level: "experienced",
  },
];

export default data_frontend;
