const questions = [
  {
    id: 1,
    question: "what do you need to start working on my project ?",
    answer:
      "It mostly depends on the type of project. But in general, you need a fair idea of what you want then we can take it from there.",
  },
  {
    id: 2,
    question: "How long will my project take to complete ?",
    answer:
      "This depends on the complexiti of the project, your available and your payment. Once you have this sorted out, I will give you a completion date.",
  },
  {
    id: 3,
    question: "Is hosting and domain registration inclusive in your pricing ?",
    answer:
      "No, hosting and domain is taken care of saperately. You can pay for it or take care of it on you own if you know how. I can provide you a free domain like `freedomain.com.np`",
  },
  {
    id: 4,
    question: "How much do you charge for an average website or app ?",
    answer:
      "Once again, this depends. But my prices are affortable so you're good.",
  },
  {
    id: 5,
    question: "Whats your payment plan ?",
    answer:
      "Payment is divided into 3. An initial 30% upfront payment to get me started once all the details of the project is set, 40% once the project is completed, and 30% once the project is deliverd.",
  },
  {
    id: 6,
    question: "What if the project doesn't turn out good ?",
    answer:
      "You will be a part of the process from the start. You will know how the whole project is going until the end. And i will make sure to give you the best.",
  },
];

export default questions;
